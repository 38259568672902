import { BaseState } from './-types'

export default (): BaseState => {
  return {
    language: 'en',
    sidebarOpen: false,
    infoOpen: false,
    filterOpen: true,
    mobileAvailabilityMenuOpen: false,
    meta: {},
    pageLoading: true,
    documentIsReady: false,
    landlordDetails: {},
    engine3dPluginLoading: false
  }
}
