import { BaseState } from './-types'
import constants from './-constants'

const {
  SET_LANGUAGE,
  SET_METADATA,
  SET_SIDEBAR_STATE,
  SET_FILTER_STATE,
  SET_INFO_STATE,
  SET_PAGE_LOADING_STATE,
  SET_MOBILE_AVAILABILITY_MENU,
  SET_DOCUMENT_READY_STATE,
  SET_ENGINE_3D_PLUGIN_LOADING
} = constants.mutation

export default {
  [SET_LANGUAGE](state: BaseState, langCode: any) {
    state.language = langCode
  },
  [SET_SIDEBAR_STATE](state: BaseState, isOpen: any) {
    state.sidebarOpen = isOpen
  },
  [SET_FILTER_STATE](state: BaseState, isOpen: any) {
    state.filterOpen = isOpen
  },
  [SET_INFO_STATE](state: BaseState, isOpen: any) {
    state.infoOpen = isOpen
  },
  // @ts-ignore / TODO - rethink the eslint rule
  [SET_METADATA](state: BaseState, { metaData, rootState }) {
    if (metaData.landlordDetails) {
      state.landlordDetails = metaData.landlordDetails
      delete metaData.landlordDetails
    }
    state.meta = metaData
    rootState.project.projects = state.landlordDetails.projects
  },
  [SET_PAGE_LOADING_STATE](state: BaseState, payload: boolean) {
    state.pageLoading = payload
  },
  [SET_MOBILE_AVAILABILITY_MENU](state: BaseState, payload: boolean) {
    state.mobileAvailabilityMenuOpen = payload
  },
  [SET_DOCUMENT_READY_STATE](state: BaseState, payload: boolean) {
    state.documentIsReady = payload
  },
  [SET_ENGINE_3D_PLUGIN_LOADING](state: BaseState, payload: boolean) {
    state.engine3dPluginLoading = payload
  }
}
