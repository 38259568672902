// Actions
// const LOAD_METADATA = 'loadMetaData'
// const CHANGE_SIDEBAR_STATE = 'changeSidebarState'
// const CHANGE_FILTER_STATE = 'changeFilterState'
// const CHANGE_PAGE_LOADING_STATE = 'changePageLoadingState'
// const TOGGLE_SIDEBAR = 'toggleSidebar'
// const TOGGLE_FILTER = 'toggleFilter'
// const TOGGLE_INFO = 'toggleInfo'
// const CLOSE_FILTER = 'closeFilter'
// const TOGGLE_MOBILE_AVAILABILITY_MENU = 'toggleMobileAvailabilityMenu'
// const CHANGE_MOBILE_AVAILABILITY_MENU = 'changeMobileAvailabilityMenu'
// const CHANGE_DOCUMENT_READY_STATE = 'changeDocumentReadyState'
//
// // Mutations
// const SET_METADATA = 'setMetaData'
// const SET_SIDEBAR_STATE = 'setSidebarState'
// const SET_FILTER_STATE = 'setFilterState'
// const SET_INFO_STATE = 'setInfoState'
// const SET_PAGE_LOADING_STATE = 'setPageLoadingState'
// const SET_MOBILE_AVAILABILITY_MENU = 'setMobileAvailabilityMenu'
// const SET_DOCUMENT_READY_STATE = 'setDocumentReadyState'

export { FloorViewMode, SpaceFloorViewMode } from '@bright-spaces/engine-3d/dist/definitions'
// Namespace
const NAMESPACE = 'building'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export enum CurrentView {
  BUILDING = 'building',
  FLOOR = 'floor'
}

export default {
  CurrentView,
  action: {},
  mutation: {},
  namespace: NAMESPACE,
  withNamespace
}
