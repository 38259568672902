// import baseConstants from './base/-constants'
//
import WebRequest from '~/helpers/api/WebRequest'
import { WebApi } from '~/helpers/api/WebApi'

export default {
  getActiveProjectSettings(state: any) {
    const allSettings = state.base.meta.generalConfig.expModelDisplaySettings
    return allSettings[state.project.activeProject]
  },
  activeProjectSpacesByMeshCode(state: any): Record<string, any> {
    const project = state.building.project.projects.find(
      (p: { slug: string }) => p.slug === state.project.activeProject
    )
    if (!project) return {}
    const spacesByCode: Record<string, any> = {}
    state.building.project.spaces
      .filter((sp: any) => sp.project_id === project.id)
      .forEach((sp: any) => {
        const codeParts = sp.code.split('/')
        if (codeParts.length !== 3) return

        spacesByCode[`${codeParts[0]}_${codeParts[2]}`] = sp
      })
    return spacesByCode
  },
  cdnBase(state: any) {
    return state.base.meta.generalConfig.cdnBase
  },
  getShortlist(state: any) {
    return state.base.meta.generalConfig.shortlist
  },
  logoImage(state: any) {
    return state.base.meta.generalConfig.logo
  },
  logoImageWhite(state: any) {
    return state.base.meta.generalConfig.logoWhite
  },
  getActiveProjectData(state: any) {
    return state.project.projects.find((item: any) => item.slug === state.project.activeProject)
  },
  requestHelper(state: any) {
    return WebRequest
  }
}
