import { ProjectState } from './-types'
import constants from './-constants'

const {
  SET_LANDLORD_DETAILS,
  SET_PROJECT_AVAILABILITY,
  SET_LOADING_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_ACTIVE_PROJECT,
  SET_FILTER_STATE,
  SET_EMPTY_FILTERS,
  SET_TOTAL_AVAILABLE_RESULTS,
  SET_CURRENT_SPACE
} = constants.mutation

export default {
  [SET_LANDLORD_DETAILS](state: ProjectState, data: any) {
    state.projects = data.projects
    state.buildings = data.buildings
  },
  [SET_PROJECT_AVAILABILITY](state: ProjectState, data: any) {
    state.project.buildings = data.buildings
    state.project.floors = data.floors
    state.project.spaces = data.spaces
    state.project.amenities = data.amenities
  },
  [SET_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.isLoading = payload
  },
  [SET_PROJECT_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.project.isLoading = payload
  },
  [SET_ACTIVE_PROJECT](state: ProjectState, payload: string) {
    state.activeProject = payload
  },
  [SET_CURRENT_SPACE](state: ProjectState, payload: string) {
    state.project.currentSpaceId = payload
  },
  [SET_FILTER_STATE](state: ProjectState, payload: any) {
    state.filters[payload.type] = payload.value
  },
  [SET_EMPTY_FILTERS](state: ProjectState, payload: any) {
    Object.keys(payload).forEach(function (key) {
      state.filters[key] = payload[key]
    })
  },
  [SET_TOTAL_AVAILABLE_RESULTS](state: ProjectState, payload: any) {
    state.availabilityResults.availableSpaces = payload.availableSpaces || 0
  }
}
